<template>
  <div class="support_requests">
    <h2>Portal User Management</h2>
    <div class="mb-3 mt-3">
      <b-table
        ref="support_table"
        striped
        bordered
        :fields="table_fields"
        :items="support_requests"
      >
        <template #cell(show_details)="row">
          <b-button
            variant="primary"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button>
        </template>

        <template #cell(complete)="data">
          <b-button @click="saveStatus(data.item)" variant="primary" size="sm" class="mr-2">
            {{  (data.item.status == 'N') ? 'Open': 'Closed' }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2 text-left">
              <b-col><b>Comments:</b> &nbsp;{{ row.item.comments }}</b-col>
            </b-row>
            <b-row class="mb-2 text-left">
              <b-col
                ><b-button variant="primary" size="sm" @click="editNote(row.item)" 
                  ><b>Notes</b></b-button
                >
                &nbsp;{{ row.item.notes }}</b-col
              >
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>
    <b-modal id="modal-notes" size="lg" title="Notes" @ok="saveNotes">
      <b-form-textarea
        id="notes-textarea"
        v-model="notes"
        rows="10"
      ></b-form-textarea>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table_fields: [
        "complete",
        "show_details",
        { key: "name", label: "Name" },
        { key: "pws_id", label: "PWS ID" },
        { key: "pws_name", label: "PWS Name" },
        { key: "email", label: "E-Mail" },
        { key: "phone", label: "Phone" },
        { key: "submission_ts", label: "Submitted" },
        { key: "update_ts", label: "Updated" }
      ],
      support_requests: [],
      note_id: null,
      notes: null,
      current_sr_item: null,
      last_name: null,
      filter: null,
      filterOn: [],
      current_page: 1,
      per_page: 10
    }
  },
  mounted() {
    //pass
    //this.fetchUsers()
    this.fetchNewSupportRequests()
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      //this.fetchUsers()
    },
    editNote(item) {
      this.current_sr_item = item
      this.note_id = item.id
      this.notes = item.notes
      this.$bvModal.show('modal-notes')
    },
    async saveNotes() {
      console.log(this.notes)
      console.log('saving notes to ID ' + this.note_id)
      if (await this.syncNotes()) {
        this.current_sr_item.notes = this.notes
      }
    },
    async syncNotes() {
      const path = process.env.VUE_APP_API_URI + "/support-notes"
      try {
        console.log("saving notes")
        await this.axios.post(path, { id: this.note_id, notes: this.notes })
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async saveStatus(item) {
      const path = process.env.VUE_APP_API_URI + "/support-request-status"
      try {
        let new_status = item.status == 'N' ? 'C': 'N'
        console.log("setting status")
        await this.axios.post(path, { id: item.id, status: new_status })
        item.status = new_status
      } catch (error) {
        console.log(error)
      }
    },
    async fetchNewSupportRequests() {
      const path = process.env.VUE_APP_API_URI + "/support-requests"
      try {
        console.log("fetching new support requests")
        let response = await this.axios.get(path, { params: { status: "N" } })
        if (response.data) {
          this.support_requests = response.data.support_requests
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
